
























































































































import {Component, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterRepository from '@/components/filters/FilterRepository.vue'
import {Repository} from '@/model/resource/Repository'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'
import {ListRepositorySchema} from '@/schema/resource/Repository/ListRepositorySchema'
import {ExportRepositorySchema} from '@/schema/resource/Repository/ExportRepositorySchema'

@Component({
  components: {FilterToggle, FilterRepository},
})
export default class ListRepositoryView extends Mixins(MixinRouteMatch) {
  schema = new ListRepositorySchema()
  collection = new RepositoryCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: Repository) {
    this.$nav.pushByName('editRepository', item.$id)
  }

  async removeItem(item: Repository) {
    await this.$dialog.remove(item, 'schema.ListRepository.confirmRemove')
    await item.removeRepository()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new RepositoryCollection().clearFilters().addFilter(params)

    await coll.listExportRepository()
    this.$xlsx.downloadFromSchema(coll.items, new ExportRepositorySchema())
  }
}
