/**
 * Filter Schema of Repository
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IRepositoryCollectionResourcesHolder} from '@/model/collection/RepositoryCollection'
import {Repository} from '@/model/resource/Repository'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'

/* TODO: review generated schema */
export class FilterRepositorySchema extends DefaultSchema
  implements IRepositoryCollectionResourcesHolder {
  collectionOrganization = new OrganizationCollection().noPagination()

  readonly name = 'FilterRepository'

  readonly fieldSet: FieldSet<Repository> = {
    organization: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionOrganization.items,
        multiple: true,
        closeOnSelect: false,
        preserveSearch: true,
        label: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
  }
}
