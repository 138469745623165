
















import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'
import {FilterRepositorySchema} from '@/schema/resource/Repository/FilterRepositorySchema'

@Component
export default class FilterRepository extends Vue {
  @Prop({type: Object, required: true}) collection!: RepositoryCollection

  schema = new FilterRepositorySchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionOrganization.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.collection.setCurrentPage(0)
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }
}
