/**
 * List Schema of Repository
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Repository} from '@/model/resource/Repository'

/* TODO: review generated schema */
export class ListRepositorySchema extends DefaultSchema {
  readonly name = 'ListRepository'

  readonly fieldSet: FieldSet<Repository> = {
    idRepositoryPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    organization: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.organization?.title,
      },
    }),
    labelsTokenized: (): FieldComponent => ({
      is: Component.Render,
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    description: (): FieldComponent => ({
      is: Component.Render,
    }),
    colorHex: (): FieldComponent => ({
      is: Component.Render,
    }),
    repositoryUrl: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
